import { render, staticRenderFns } from "./MvRow.vue?vue&type=template&id=71b4f1ff&scoped=true&"
import script from "./MvRow.vue?vue&type=script&lang=js&"
export * from "./MvRow.vue?vue&type=script&lang=js&"
import style0 from "./MvRow.vue?vue&type=style&index=0&id=71b4f1ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b4f1ff",
  null
  
)

export default component.exports